<template>
  <div :style="this.$vuetify.theme.dark? 'background-color: #1E1E1E;' : ''" class="card main-card">
    <div :class="[this.$vuetify.theme.dark? 'dark-bg' : 'white']" class="card-title card-header-tab card-header">
      <div class="card-header-title font-size-lg text-capitalize font-weight-normal">
        {{ this.$t('settings.lang_nav_settings_cashierSettings') }}
      </div>
      <div class="btn-actions-pane-right actions-icon-btn">
        <div class="btn-actions-pane-right actions-icon-btn">
        </div>
      </div>
    </div>
    <v-divider class="pa-0 ma-0"/>
    <div>
      <v-progress-linear
          v-if="loading"
          class="ma-0"
          color="primary"
          indeterminate
      ></v-progress-linear>
    </div>
    <v-form ref="form" v-model="valide">
      <div class="card-body">
        <v-row>
          <v-col cols="12">
            <v-row>

              <v-col class="pb-0 mb-0" cols="12" md="6" sm="6">
                <v-text-field v-model="aliasName"
                              :data-layout="KEYBOARD.KEYSETS.NORMAL" :label="$t('generic.lang_alias')"
                              outlined @focus="showTouchKeyboard"/>
              </v-col>

              <v-col class="pb-0 mb-0" cols="12" md="6" sm="6">
                <v-text-field v-model="countDown"
                              :data-layout="KEYBOARD.KEYSETS.NORMAL" min="0" :rules="[v => v!==null&&v>=0]"
                              :label="$t('accounting.lang_backtoCashierAfterPayment')"
                              outlined @focus="showTouchKeyboard" type="number"/>
              </v-col>

              <v-col class="pt-0 mt-0 pb-0 mb-0 mt-1" cols="12">
                <v-textarea v-model="notes"
                            :data-layout="KEYBOARD.KEYSETS.NORMAL" :label="this.$t('generic.lang_notes')" outlined
                            @focus="showTouchKeyboard"/>
              </v-col>
              <v-col class="pt-0 mt-0 pb-0 mb-0 mt-1" cols="12" lg="4" md="6" sm="6" xl="4">
                <v-autocomplete multiple :label="$t('generic.lang_AdditionalStatistics')" :items="additinals"
                                :loading="loadingAdditionals" v-model="selectedAdditionals" item-text="name"
                                item-value="uuid" outlined>

                </v-autocomplete>
              </v-col>
              <v-col cols="12" lg="4" md="6" sm="6" xl="4">
                <v-checkbox v-model="cancellationPassworActive" :label="$t('settings.lang_voidPasswordEnabled')"/>
              </v-col>

              <v-col cols="12" lg="4" md="6" sm="6" xl="4">
                <v-checkbox :disabled="!this.cancellationPassworActive" v-model="cancelOpenItemsPasswordActive"
                            :label="$t('settings.lang_voidPasswordImmediate')"/>
              </v-col>
              <v-col class="pt-0 mt-0 pb-0 mb-0 mt-1" cols="12" lg="4" md="6" sm="6" xl="4">
                <v-checkbox v-model="showAdditionals" :label="$t('generic.lang_AdditionalStatisticsDisplay')"/>
              </v-col>

              <v-col cols="12" lg="4" md="6" sm="6" xl="4">
                <v-checkbox v-model="inHouseTakeAwayRequired" :label="$t('settings.lang_ausserHausZwangsab')"/>
              </v-col>
              <v-col cols="12" lg="4" md="6" sm="6" xl="4">
                <v-checkbox v-model="takeAwayStandard"
                            :label="$t('settings.lang_settingAusserHausStandardTresenVerkauf')"/>
              </v-col>
              <v-col cols="12" lg="4" md="6" sm="6" xl="4">
                <v-checkbox v-model="openCashDrawerDirectSale" :label="$t('settings.lang_openCashdrawerOnlyTresenVK')"/>
              </v-col>
              <v-col cols="12" lg="4" md="6" sm="6" xl="4">
                <v-checkbox v-model="disableDirectSaleReceiptPrint"
                            :label="$t('erp.lang_printNoIfTresenVK')"/>
              </v-col>
              <v-col cols="12" lg="4" md="6" sm="6" xl="4">
                <v-checkbox v-model="disableOrderbonPrintingDirectSale"
                            :label="$t('erp.lang_printNoOrderbonifTresenVK')"/>
              </v-col>
              <v-col cols="12" lg="4" md="6" sm="6" xl="4">
                <v-checkbox v-model="disableCashDrawer" :label="$t('generic.lang_NOopencashier')"/>
              </v-col>

              <v-col cols="12" lg="4" md="6" sm="6" xl="4">
                <v-checkbox v-model="enableCameraInApp" :label="this.$t('erp.stockTakingUseMobileCamera')"/>
              </v-col>
              <v-col cols="12" lg="4" md="6" sm="6" xl="4">
                <v-checkbox v-model="disableAfterSaleDialog" :label="$t('generic.lang_deactivateAfterSaleDialog')"/>
              </v-col>
              <v-col cols="12" lg="4" md="6" sm="6" xl="4">
                <v-checkbox v-model="disableImageDisplay" :label="$t('settings.lang_settingDeactivateDisplayProductImageOnPLU')"/>
              </v-col>
              <v-col v-if="$store.getters['permissions/checkModule'](54)" cols="12" lg="4" md="6" sm="6" xl="4">
                <v-checkbox v-model="showTablebeeNotifications" :label="$t('tablebee.lang_showTablebeeNotification')"/>
              </v-col>
              <v-col v-if="$store.getters['permissions/checkModule'](13)" cols="12" lg="4" md="6" sm="6" xl="4">
                <v-checkbox v-model="showLocalbeeNotifications" :label="$t('delivery.lang_showLocalbeeNotifications')"/>
              </v-col>
              <v-col cols="12" lg="4" md="6" sm="6" xl="4">
                <v-checkbox v-model="displayCashierAlias" :label="$t('settings.lang_displayCashierAlias')"/>
              </v-col>
              <v-col cols="12" lg="4" md="6" sm="6" xl="4">
                <v-checkbox v-model="printInterimOrderReceipt" :label="$t('settings.lang_printInterimOrderReceipt')"/>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>

      <v-divider class="ma-0 pa-0"/>
      <div :class="[this.$vuetify.theme.dark? 'dark-bg' : 'white']" class="card-footer text">
        <v-row justify="end" no-gutters>
          <v-btn :disabled="loading||!valide" color="success" large @click="update">
            {{ $t('generic.lang_apply') }}
          </v-btn>
        </v-row>
      </div>
    </v-form>
    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard v-if="touchKeyboard.visible" id="onScreenKeyboard"
                          :accept="hideTouchKeyboard"
                          :cancel="hideTouchKeyboard" :defaultKeySet="touchKeyboard.keySet"
                          :input="touchKeyboard.input" :layout="touchKeyboard.layout"
                          :options="touchKeyboard.options" class="internalWidth"/>
    </div>
  </div>
</template>

<script>
import {Events} from "../../../plugins/events";
import mixin from "../../../mixins/KeyboardMixIns";
import {ENDPOINTS} from "../../../config";

export default {
  name: "EditCashierOptionsComponent",
  mixins: [mixin],
  data() {
    return {
      ENDPOINTS,
      rules: {
        required: (field) => {
          return ((field && field !== '' && field.toString().length > 0) || this.$t("generic.lang_requiredField"))
        },
      },
      id: null,
      loading: false,
      valide: false,
      aliasName: null,
      notes: null,
      cancellationPassword: null,
      cancelOpenItemsPasswordActive: false,
      cancellationPassworActive: null,
      inHouseTakeAwayRequired: null,
      disableOrderbonPrintingDirectSale: null,
      disableCashDrawer: null,
      openCashDrawerDirectSale: null,
      disableDirectSaleReceiptPrint: null,
      enableCameraInApp: false,
      takeAwayStandard: null,
      disableAfterSaleDialog: false,
      disableImageDisplay: false,
      displayCashierAlias: false,
      countDown: 0,
      loadingAdditionals: false,
      additinals: [],
      selectedAdditionals: [],
      showAdditionals: false,
      showTablebeeNotifications: false,
      showLocalbeeNotifications: false,
      printInterimOrderReceipt: false,
    }
  },
  methods: {
    getData() {
      this.loading = true;
      this.axios.post(ENDPOINTS.SETTINGS.BASIC.SYSTEMSETTINGS.CASHIER.OPTIONS.GET,
          {cashierID: this.id})
          .then(res => {
            if (res.data.status === "SUCCESS") {

              this.aliasName = res.data.settings.aliasName;
              this.notes = res.data.settings.notes;
              this.cancellationPassword = res.data.settings.cancellationPassword;
              this.cancellationPassworActive = !!res.data.settings.cancellationPasswordActive;
              this.cancelOpenItemsPasswordActive = !!res.data.settings.cancelOpenItemsPasswordActive;
              this.inHouseTakeAwayRequired = !!res.data.settings.inHouseTakeAwayRequired;
              this.disableOrderbonPrintingDirectSale = !!res.data.settings.disableOrderbonPrintingDirectSale;
              this.disableCashDrawer = !!res.data.settings.disableCashDrawer;
              this.openCashDrawerDirectSale = !!res.data.settings.openCashDrawerDirectSale;
              this.disableDirectSaleReceiptPrint = !!res.data.settings.disableDirectSaleReceiptPrint;
              this.displayCashierAlias = !!res.data.settings.displayCashierAlias;
              this.takeAwayStandard = !!res.data.settings.takeAwayStandard;
              this.enableCameraInApp = res.data.settings.enableCameraInApp === 1;
              this.disableAfterSaleDialog = !!res.data.settings.disableAfterSaleDialog;
              this.disableImageDisplay = !!res.data.settings.disableImageDisplay;
              this.countDown = parseInt(Number(res.data.settings.ReturnToPosAfter));
              this.showAdditionals = res.data.settings.askForAdditionalStatistics;
              this.selectedAdditionals = res.data.settings.additional_statistics;
              this.printInterimOrderReceipt = res.data.settings.printInterimOrderReceipt;
              if(this.$store.getters['permissions/checkModule'](13)){
                this.showLocalbeeNotifications = res.data.settings.showLocalbeeNotifications;
              }

              if(this.$store.getters['permissions/checkModule'](54)){
                this.showTablebeeNotifications = res.data.settings.showTablebeeNotifications;
              }


            } else {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_errorOccurred'),
                color: "error",
              });
            }
          }).catch(err => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(fin => {
        this.loading = false;
      })
    },
    getAdditionalStatistics() {
      this.loadingAdditionals = true;
      this.axios.post(ENDPOINTS.SETTINGS.ADDITIONALSTATISTICS.GET)
          .then(res => {
            if (res.data.status === 'SUCCESS') {
              this.additinals = res.data.data
            } else {
              Events.$emit("showSnackbar", {
                message: res.data.msg || res.data.status,
                color: "error"
              });
            }
          }).catch(err => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.loadingAdditionals = false
      })
    },
    update() {

      if (!this.$refs.form.validate()) {
        return;
      }

      this.loading = true;
      this.axios.post(ENDPOINTS.SETTINGS.BASIC.SYSTEMSETTINGS.CASHIER.OPTIONS.UPDATE, {
        cashierID: this.id,
        aliasName: this.aliasName,
        notes: this.notes,
        cancellationPassword: this.cancellationPassword,
        cancellationPasswordActive: (this.cancellationPassworActive) ? 1 : 0,
        cancelOpenItemsPasswordActive: (this.cancelOpenItemsPasswordActive) ? 1 : 0,
        inHouseTakeAwayRequired: (this.inHouseTakeAwayRequired) ? 1 : 0,
        disableOrderbonPrintingDirectSale: (this.disableOrderbonPrintingDirectSale) ? 1 : 0,
        disableCashDrawer: (this.disableCashDrawer) ? 1 : 0,
        openCashDrawerDirectSale: (this.openCashDrawerDirectSale) ? 1 : 0,
        disableDirectSaleReceiptPrint: (this.disableDirectSaleReceiptPrint) ? 1 : 0,
        takeAwayStandard: (this.takeAwayStandard) ? 1 : 0,
        enableCameraInApp: (this.enableCameraInApp) ? 1 : 0,
        disableAfterSaleDialog: (this.disableAfterSaleDialog) ? 1 : 0,
        disableImageDisplay: (this.disableImageDisplay) ? 1 : 0,
        ReturnToPosAfter: parseInt(Number(this.countDown)),
        askForAdditionalStatistics: this.showAdditionals,
        additional_statistics: this.selectedAdditionals,
        showLocalbeeNotifications:this.showLocalbeeNotifications,
        showTablebeeNotifications:this.showTablebeeNotifications,
        displayCashierAlias:this.displayCashierAlias,
        printInterimOrderReceipt:this.printInterimOrderReceipt,

      }).then(res => {
        if (res.data.STATUS === "SUCCESS") {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_dataUpdated'),
            color: "success"
          });
        } else if (res.data.STATUS === "FAILED") {
          Events.$emit("showSnackbar", {
            message: res.data.msg,
            color: "error",
          });
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch(err => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(fin => {
        this.loading = false;
      })
    }
  },
  mounted() {
    this.id = parseInt(this.$route.params.id);
    this.getData();
    this.getAdditionalStatistics();
  }
}
</script>

<style scoped>

</style>
