<template>
    <v-container class="pa-0" fluid>
        <page-title :heading="this.$t('settings.lang_cashierOptions')"
                    :subheading="this.$t('settings.lang_cashierOptions')"
                    :icon="icon"
                    show-previous-button
        ></page-title>
        <div class="app-main__inner">
            <EditCashierOptionsComponent/>
            <CountingProtocolDialogComponent/>
        </div>
    </v-container>
</template>

<script>
    import PageTitle from "../../../Layout/Components/PageTitle.vue";
    import EditCashierOptionsComponent from "../../../components/settings/sysetmdata/EditCashierOptionsComponent";
    import CountingProtocolDialogComponent from "../../../components/pos/CountingProtocolDialogComponent";

    export default {
        name: "EditCashierOptions",
        components: {
            CountingProtocolDialogComponent,
            EditCashierOptionsComponent,
            PageTitle,
        },

        data: () => ({
            icon: ' pe-7s-settings icon-gradient bg-tempting-azure',
        })
    }
</script>

